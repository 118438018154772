
  <template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">New Karyakartas</div>
  </div>
  <div class="layout-content-section">
    <div
      class="
        content-header-section
        d-flex
        justify-content-start
        align-items-center
      "
    >
      <div class="content-subheader-box">Add New Karyakarta</div>
    </div>
    <div class="company-detail-box-outer" style="height: calc(100vh - 201px)">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formTitleInput" class="form-label"
                  >First Name<span class="text-danger">*</span></label
                >
                <div class="input-group custom-input-group">
                  <div class="col-lg-4 col-md-4 col-12">
                    <Multiselect
                      v-model="title"
                      :options="titleList"
                      :searchable="false"
                      label="label"
                      placeholder="Select"
                      class="multiselect-custom"
                      :canClear="false"
                      :closeOnSelect="true"
                      :object="true"
                      noOptionsText="No Result"
                      :canDeselect="false"
                    />
                  </div>
                  <div class="col-lg-8 col-md-8 col-12">
                    <input
                      type="text"
                      v-model="firstname"
                      class="form-control text-capitalize"
                      id="formFirstNameInput"
                      placeholder="Enter First Name"
                      autocomplete="off"
                      maxlength="20"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formLastNameInput" class="form-label"
                  >Middle Name<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="middlename"
                  class="form-control text-capitalize"
                  id="formLastNameInput"
                  placeholder="Enter Middle Name"
                  autocomplete="off"
                  maxlength="20"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formLastNameInput" class="form-label"
                  >Last Name<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="lastname"
                  class="form-control text-capitalize"
                  id="formLastNameInput"
                  placeholder="Enter Last Name"
                  autocomplete="off"
                  maxlength="20"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label class="form-label"
                  >Mobile Number<span class="text-danger">*</span></label
                >
                <div class="input-group custom-input-group">
                  <div class="col-lg-4 col-md-4 col-12">
                    <Multiselect
                      v-model="countrycode"
                      :options="countryCodeList"
                      :searchable="true"
                      label="label"
                      placeholder="Select"
                      class="multiselect-custom"
                      :canClear="false"
                      :closeOnSelect="true"
                      :object="true"
                      noOptionsText="No Result"
                      :canDeselect="false"
                    />
                  </div>
                  <div class="col-lg-8 col-md-8 col-12">
                    <input
                      type="text"
                      v-model="mobile"
                      class="form-control"
                      id="formMobileNoInput"
                      placeholder="Enter mobile number"
                      maxlength="10"
                      @keypress="onlyNumber"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formBirthDateInput" class="form-label"
                  >Registration Date</label
                >
                <datepicker
                  v-model="registrationdate"
                  inputFormat="dd/MM/yyyy"
                  :upperLimit="new Date()"
                  :typeable="false"
                  :clearable="false"
                  placeholder="DD/MM/YYYY"
                  class="custom-date-picker form-control"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formEmailInput" class="form-label">Registration Number<span class="text-danger">*</span></label>
                <input
                  type="text"
                  v-model="registrationnumber"
                  class="form-control text-uppercase"
                  id="formMobileNoInput"
                  placeholder="Enter Registration Number"
                  autocomplete="off"
                  maxlength="10"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formBirthDateInput" class="form-label"
                  >Birth Date<span class="text-danger">*</span></label
                >
                <datepicker
                  v-model="birthdate"
                  inputFormat="dd/MM/yyyy"
                  :upperLimit="new Date()"
                  :typeable="false"
                  :clearable="false"
                  placeholder="DD/MM/YYYY"
                  class="custom-date-picker form-control"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formEmailInput" class="form-label">Age<span class="text-danger">*</span></label>
                <input
                  type="text"
                  v-model="age"
                  class="form-control"
                  id="formEmailInput"
                  placeholder="Enter Age"
                  autocomplete="off"
                  maxlength="50"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formEmailInput" class="form-label"
                  >Email Id<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="email"
                  class="form-control"
                  id="formEmailInput"
                  placeholder="Enter Email Id"
                  autocomplete="off"
                  maxlength="50"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formEmailInput" class="form-label">Designation<span class="text-danger">*</span></label>
                <Multiselect
                  v-model="designation"
                  :options="designationList"
                  :searchable="true"
                  label="label"
                  placeholder="Select"
                  class="multiselect-custom"
                  :canClear="false"
                  :closeOnSelect="true"
                  :object="true"
                  noOptionsText="No Result"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formEmailInput" class="form-label">Area<span class="text-danger">*</span></label>
                <Multiselect
                  v-model="area"
                  :options="areaList"
                  :searchable="true"
                  label="label"
                  placeholder="Select"
                  class="multiselect-custom"
                  :canClear="false"
                  :closeOnSelect="true"
                  :object="true"
                  noOptionsText="No Result"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formEmailInput" class="form-label">Pin Code<span class="text-danger">*</span></label>
                <input
                  type="text"
                  v-model="pincode"
                  class="form-control"
                  id="formMobileNoInput"
                  placeholder="Enter Pin Code"
                  maxlength="6"
                  @keypress="onlyNumber"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formEmailInput" class="form-label">Profession<span class="text-danger">*</span></label>
                <Multiselect
                  v-model="profession"
                  :options="professionList"
                  :searchable="true"
                  label="label"
                  placeholder="Select"
                  class="multiselect-custom"
                  :canClear="false"
                  :closeOnSelect="true"
                  :object="true"
                  noOptionsText="No Result"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="custom-form-group">
                <label for="formEmailInput" class="form-label">Company / Business Name<span class="text-danger">*</span></label>
                <input
                  type="text"
                  v-model="companyname"
                  class="form-control"
                  id="formMobileNoInput"
                  placeholder="Enter Company Name"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="custom-form-group text-center">
                <button type="button" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: { value: "1", label: "Mr." },
      titleList: [
        { value: "1", label: "Mr." },
        { value: "2", label: "Mrs." },
      ],
      firstname: "",
      middlename: "",
      lastname: "",
      countrycode: { value: "101", label: "+91" },
      countryCodeList: [
        { value: "101", label: "+91" },
        { value: "102", label: "+92" },
        { value: "103", label: "+93" },
      ],
      mobile: "",
      email: "",
      designation: "",
      designationList: [
        { value: "1", label: "Booth Pramukh" },
        { value: "2", label: "Karyakarta" },
      ],
      birthdate: null,
      age: "",
      area: "",
      pincode: "",
      areaList: [
        { value: "1", label: "Nagala Park" },
        { value: "2", label: "Baner" },
        { value: "3", label: "Ajara" },
      ],
      profession: "",
      companyname: "",
      professionList: [
        { value: "1", label: "Salaried" },
        { value: "2", label: "Business" },
      ],
      registrationdate: null,
      registrationnumber: "",
    };
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}
.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}
.company-detail-box-outer {
  padding: 16px 16px 0;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}
.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}
.customer-label-group {
  margin-bottom: 16px;
}
.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}
.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}
.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 525px;
}
.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}
.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}
.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}
.text-star-blank {
  color: #c1c5c9;
}
</style>